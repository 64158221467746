<template>
    <app-wrapper>
        <template v-slot:child>
            <div class="w-90 center mt4 mini-spacing">
                <!-- Back button -->
                <div class="font-w2" style="font-size: 18px">
                    <router-link :to="{ name: 'Products' }"> &#8592; &nbsp; Back</router-link>
                </div>

                <!-- content -->
                <div class="w-60-l w-100 center mt5-l mt3">
                    <div class="f3 b pb4">Import Customer</div>

                    <p class="lh-copy" style="font-size: 16px">
                        We found some column in the uploaded CSV file. Kindly match the detected columns wwith what its value
                        represents. Select other to enter a custom attribute (what it is). Ignore columns you are not interested
                        in.
                    </p>

                    <div class="flex justify-between pa3 mv3-l mv4 br2 font-w2" style="background: rgb(207, 232, 240)">
                        <div class="w-40">CSV colunms</div>
                        <div class="w-40">What it is</div>
                        <div class="w-10"></div>
                    </div>

                    <!--eslint-disable-next-line-->
                    <template v-for="(rowName, index) in state.dataSets" :key="index">
                        <!-- cvs inputs columns -->
                        <div class="pa3-l box-border-bottom pb3">
                            <div class="flex justify-between items-center">
                                <select :name="rowName.name" :id="rowName.name" class="w-40" v-model="rowName.name">
                                    <!-- eslint-disable-next-line-->
                                    <template v-for="newRow of importedInventoryArray?.newKeys" :key="newRow">
                                        <option :value="newRow">{{ newRow }}</option>
                                    </template>
                                </select>

                                <select name="" id="" class="w-40" v-model="rowName.value">
                                    <!-- eslint-disable-next-line-->
                                    <template v-for="correspondingRow of state.defaultInventoryKeys" :key="correspondingRow">
                                        <option :value="correspondingRow?.value">{{ correspondingRow?.name }}</option>
                                    </template>
                                </select>

                                <div class="b tc import-d-btn w-10" style="color: #132c8c">
                                    <a href=""  @click.prevent="deleteRow(index, rowName)">
                                        <img src="../../assets/images/delete.svg" alt="" />
                                    </a>
                                </div>
                            </div>
                            <div></div>
                            <div></div>
                        </div>
                    </template>

                    <!-- add another btn -->
                    <template v-if="state?.dataSets?.length">
                        <div class="mt3">
                            <button class="btn2" @click.prevent="handleAddInventoryHeader">Add another</button>
                        </div>
                        <div class="mt3">
                            <button
                                class="btn1"
                                @click.prevent="handleOnsubmitInventory"
                                type="submit"
                                :disabled="state.disableButton"
                            >
                                {{ state.disableButton ? 'Importing..., please wait' : 'Complete import' }}
                            </button>
                        </div>

                        <!-- Preview  -->
                        <div class="b mv4">Preview selected column values</div>
                        <div v-for="sample of importedInventoryArray?.defaultPreview" 
                            :key="sample" 
                            class="flex justify-between box-border-bottom w-40-l w-70 pv3"
                        >
                            <div>{{ sample?.name }}</div>
                            <div>{{ sample?.value }}</div>
                        </div>
                    </template>
                </div>
            </div>
        </template>
    </app-wrapper>
</template>

<script>
import { computed, onMounted, reactive, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import AppWrapper from '@/layout/AppWrapper'

export default {
    name: 'ImportProducts',
    components: { AppWrapper },

    setup() {
        const route = useRoute()
        const store = useStore()
        const importId = route?.query?.importId
        const importedInventoryArray = computed(() => store?.state?.Inventory?.importedInventoryArray)

        const initialState = () => ({
            disableButton: false,
            dataSets: [],
            importedInventoryKeys: [],
            userSetImportedInventoryKeys: [],
            defaultInventoryKeys: [
                // { name: 'Choose Field', value: '' },
                { name: 'Name', value: 'name' },
                { name: 'SKU', value: 'sku' },
                { name: 'Barcode', value: 'barcode' },
                { name: 'Description', value: 'description' },
                { name: 'Category', value: 'category' },
                { name: 'Brand', value: 'brand' },
                { name: 'Size', value: 'size' },
                { name: 'Color', value: 'color' },
                { name: 'Measurement Unit', value: 'measurementUnit' },
                { name: 'Selling Price', value: 'sellingPrice' },
                { name: 'Buying Price', value: 'buyingPrice' },
                { name: 'Quantity', value: 'quantity' },
            ],
            // { name: 'Quantity', value: 'quantity' },
            // { name: 'Reorder Level', value: 'reorderLevel' },
            // { name: 'Reorder Quantity', value: 'reorderQuantity' },
            // { name: 'Status', value: 'status' },
            // { name: 'Image', value: 'image' },
        })
        const state = reactive(initialState())

        watch(
            () => [importedInventoryArray?.value, state.userSetImportedInventoryKeys?.length, state.dataSets?.length],
            // () => state.dataSets?.length,
            // (prev, next) => {
            () => {
                // if (prev !== next) {
                state.importedInventoryKeys = importedInventoryArray?.value?.newKeys?.filter((el) => {
                    // console.log(el)
                    return !state.userSetImportedInventoryKeys?.includes(el)
                })
                // }
                // eslint-disable-next-line no-unused-expressions
                // console?.log(state.dataSets, 'asdad')
            }
        )

        watch(
            () => importedInventoryArray?.value,
            (prev, next) => {
                if (prev !== next) {
                    if (!state?.dataSets?.length) {
                        state.dataSets.push({
                            // [next.newKeys[0]]: next.newKeys[0],
                            name: '',
                            value: 'name',
                        })
                    }
                }

                // eslint-disable-next-line no-unused-expressions
                // console?.log(state.importedInventoryKeys, 'asdad')
            }
        )

        const handleAddInventoryHeader = () => {
            state.userSetImportedInventoryKeys.push(state.importedInventoryKeys[state?.dataSets?.length - 1])

            // state.dataSets[state?.dataSets?.length - 1].name = el.target.value
            // state.dataSets[index].name = el.target.value

            state.dataSets.push({
                name: '',
                value: '',
            })

            // console.log(state.userSetImportedInventoryKeys, 'asdasd')
            // console.log(state.dataSets.length, 'Length')
            // console.log(state.importedInventoryKeys, 'remainder')
        }

        const deleteRow = (index, row) => {
            var idx = state.dataSets.indexOf(row);
            // console.log(idx, index);
            if (idx > -1) {
                state.dataSets.splice(idx, 1);
            }
        }

        const handleMapInventoryHeaderName = (el, index) => {
            // console.log(el, index)
            state.dataSets[index].name = el
            // state.dataSets[index].value = el
        }

        const handleMapInventoryHeaderValue = (el, index) => {
            state.dataSets[index].value = el
        }

        const handleOnsubmitInventory = () => {
            state.disableButton = true
            const inventoryHeaders = []

            // state.dataSets.forEach((el) => {
            //     if ((el.name !== '' && el.value !== '') || (el.name && !el.value)) {
            //         inventoryHeaders.push(el)
            //     }
            // })

            for (let i = 0; i < state.dataSets.length; i += 1) {
                if (state.dataSets[i].name !== '' && state.dataSets[i].value !== '') {
                    inventoryHeaders.push(state.dataSets[i])
                } else {
                    console.log(state.dataSets)
                    store.dispatch('Alert/setAlert', { message: 'Some field are empty, please  fix', status: false })
                    return
                }
            }

            // console.log(inventoryHeaders, 'inventoryHeaders')

            if (!inventoryHeaders.length) return true

            const payload = {}
            payload.query = importId
            payload.products = inventoryHeaders

            // console.log(state.dataSets, 'products')

            store.dispatch('Inventory/processImportedProducts', payload).then((resp) => {
                if (resp) {
                    state.disableButton = false
                    Object.assign(state, initialState())
                    state.dataSets.push({
                        name: '',
                        value: '',
                    })
                }
            })
        }

        onMounted(() => {
            // const importId = route?.query?.importId
            return store?.dispatch('Inventory/getImportedProducts', importId)
        })

        return {
            state,
            handleAddInventoryHeader,
            deleteRow,
            handleMapInventoryHeaderName,
            handleMapInventoryHeaderValue,
            importedInventoryArray,
            handleOnsubmitInventory,
        }
    },
}
</script>

<style scoped></style>
